/*
WorkgroupStore class handles all states, API calls, form validation
  and logic of listing, Adding, Editing and Deleting workgroup records.
*/
import { WorkgroupDto } from "views/config/workgroups/WorkgroupDto";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { deleteWorkgroup, getWorkgroups, postCreateWorkgroups, putChangeWorkgroupName } from "api/config/workgroup";

export type CreateWorkgroup = {
  workgroupName: string;
  defaultRoles: boolean;
};

export class WorkgroupsStore {

  createWorkgroupForm() {
    return new FormState({
      createWorkgroups: new FieldState<CreateWorkgroup[]>([
        { workgroupName: "", defaultRoles: false },
      ]).validators((val) => {
        this.errors = {};
        var error: string = "";
        val.forEach((entry, index) => {
          if (entry.workgroupName.trim().length === 0) {
            error = "error";
          }
          if (this.workgroups.some(workgroup => workgroup.name.trim().toLowerCase() === entry.workgroupName.trim().toLowerCase()) ||
            this.createWorkgroupsForm.$.createWorkgroups.value.some((workgroup, index2) =>
              workgroup.workgroupName !== "" && workgroup.workgroupName.trim().toLowerCase() === entry.workgroupName.trim().toLowerCase() && index2 < index)) {
            this.errors[`workgroupName${index}`] = ["A workgroup with the name specified already exists."];
            error = "error";
          }
        })
        return error;
      }),
    });
  }

  root: RootStore;
  createWorkgroupsForm = this.createWorkgroupForm();
  apiLoading: boolean = false;
  workgroups: WorkgroupDto[] = [];
  workgroupsLoading: boolean = false;
  errors: Record<string, string[]> = {};
  currentWorkgroup: WorkgroupDto | undefined = undefined;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      createWorkgroupsForm: observable,
      apiLoading: observable,
      isValidForm: computed,
      errors: observable,
      workgroups: observable,
      workgroupsLoading: observable,
      currentWorkgroup: observable,
      setCurrentWorkgroup: action.bound,

      loadWorkgroups: action.bound,
      createWorkgroups: action.bound,
      updateWorkgroupName: action.bound,
      deleteWorkgroup: action.bound,
      reset: action.bound,
    });
  }

  get isValidForm() {
    return this.createWorkgroupsForm.$.createWorkgroups.dirty && !this.createWorkgroupsForm.$.createWorkgroups.hasError;
  }

  reset() {
    this.createWorkgroupsForm.reset();
    this.workgroups = [];
    this.workgroupsLoading = false;
    this.errors = {};
    this.currentWorkgroup = undefined;
  }

  loadWorkgroups() {
    this.workgroupsLoading = true
    return getWorkgroups()
      .then((res) => this.workgroups = res)
      .finally(() => {
        this.workgroupsLoading = false
        this.createWorkgroupsForm.reset();
        this.createWorkgroupsForm.validate();
      });
  }

  createWorkgroups() {
    this.apiLoading = true;
    return postCreateWorkgroups(
      this.createWorkgroupsForm.$.createWorkgroups.value.map((workgroup) => {
        return {
          name: workgroup.workgroupName,
          defaultRoles: workgroup.defaultRoles,
        };
      })
    )
      .then (() => {
        this.loadWorkgroups()
      })
      .finally(() => this.apiLoading = false)
  }

  updateWorkgroupName(workgroupName: string) {
    if (!this.currentWorkgroup)
      return Promise.resolve()

    this.apiLoading = true;
    return putChangeWorkgroupName(this.currentWorkgroup.id, workgroupName)
      .then(() => {
        this.loadWorkgroups()
        this.setCurrentWorkgroup(undefined);
      })
      .finally(() => {
        this.apiLoading = false;
      })
  }

  deleteWorkgroup() {
    if (!this.currentWorkgroup)
      return Promise.resolve()

    this.apiLoading = true;
    return deleteWorkgroup(this.currentWorkgroup?.id)
      .then(() => {
        this.loadWorkgroups()
      })
      .finally(() => {
        this.apiLoading = false;
      })
  }

  setCurrentWorkgroup(value: WorkgroupDto | undefined) {
    this.currentWorkgroup = value;
  }

}
