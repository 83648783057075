import { icons } from "assets/icons";
import { Content, Horizontal, Vertical } from "gls/lib";
import { observer } from "mobx-react";
import { colours } from "styling/colours";
import { style } from "typestyle";
import { useWorkgroups } from "../hooks/useWorkgroups";
import { EditWorkgroupNameModal } from "./EditWorkgroupNameModal";
import { useSystemAdminPermissionStore, useWorkgroupsStore } from "contexts/RootStore.context";
import LoadingScreen from "components/Loading/LoadingScreen";
import { WorkgroupDto } from "../WorkgroupDto";
import { GenericModal } from "components/Modals/GenericModal";
import { RoundedIcon } from "components/RoundedIcon";
import DataTable, { TableColumn } from "react-data-table-component";
import { useMemo } from "react";
import DefaultCell from "components/DataTable/cells/DefaultCell";
import { Tooltip } from "components/Tooltip";
import { RowActions } from "./RowActions";
import { StyledDataTableWrapper } from "components/DataTable/style";
import { datatableStyle } from "components/DataTable/DataTableStyling.config";

const WorkgroupList = observer(() => {
  const { workgroups, workgroupsLoading } = useWorkgroupsStore();
  const { canManageGroupsAndRoles } = useSystemAdminPermissionStore();
  const {
    changeWorkgroupName,
    currentWorkgroup,
    deleteOpen,
    handleEditOnClose,
    handleOnEdit,
    isDeleting,
    setCurrentWorkgroup,
    setDeleteOpen,
    updateNameModalOpen,
    workgroupDelete,
    apiLoading,
  } = useWorkgroups();

  const renderAdministrators = (row: WorkgroupDto) =>
    row.administrators?.length > 0
      ? row.administrators.map((admin) => admin.name).join(", ")
      : "--";

  const columns = useMemo(() => {
    return [
      {
        name: 'NAME',
        selector: (row: WorkgroupDto) => <DefaultCell value={row.name} />,
        minWidth: '240px',
        maxWidth: '410px',
      },
      {
        name: 'ADMINISTRATORS',
        selector: (row: WorkgroupDto) =>
          <Tooltip
            content={renderAdministrators(row)}
            contentClassName={ellipsis}
            place="left"
            key={`administrators-column-${row.id}`}
          >
            {row.administrators?.map((administrator, index) => {
              return (
                <div>{`${administrator.name}${row.administrators.length > index ? "," : ""
                  }`}</div>
              );
            })}
          </Tooltip>,
        minWidth: '180px',
        overflow: 'true',
      },
      {
        right: true,
        sortable: false,
        cell: (row: WorkgroupDto) =>
          <RowActions
            key={`row-actions-${row.id}`}
            workgroup={row}
            handleToggleDelete={(
              open: boolean,
              workgroup: WorkgroupDto
            ) => {
              setCurrentWorkgroup(workgroup);
              setDeleteOpen(true);
            }}
            canManage={canManageGroupsAndRoles}
            onEdit={handleOnEdit}
          />,
        width: '200px',
      },
    ] as TableColumn<WorkgroupDto>[];
  }, [canManageGroupsAndRoles, handleOnEdit, setCurrentWorkgroup, setDeleteOpen])

  if (workgroupsLoading)
    return <LoadingScreen />

  if (workgroups.length === 0)
    return (
      <Content horizontalAlign="center" padding={40}>
        No Workgroups
      </Content>
    )

  return (
    <>
      <StyledDataTableWrapper
        data-test-id="table"
        className={`table-container}`}
      >
        <DataTable
          columns={columns}
          data={workgroups}
          defaultSortFieldId={4}
          defaultSortAsc={false}
          customStyles={datatableStyle}
        />
      </StyledDataTableWrapper>

      <GenericModal
        title="Delete"
        subtitle={
          <Vertical spacing={12}>
            <span className={modalTextStyle}>
              Are you sure you want to permanently delete the &nbsp;
              <strong>{currentWorkgroup?.name} workgroup</strong> and the
              roles associated with it?
            </span>
            <Horizontal verticalAlign="top" spacing={6}>
              <span>
                <icons.InfoIcon fill={colours.secondaryText} />
              </span>
              <span className={modalTextStyle}>
                Please note there are{" "}
                {/* {currentWorkgroup?.administrators.length} */}
                &nbsp; users associated to this workgroup/role. The
                association will be removed if you delete the workgroup.
              </span>
            </Horizontal>
          </Vertical>
        }
        actionName="Delete"
        actionStyle="cancel"
        icon={
          <RoundedIcon>
            <icons.OutlineDeleteIcon height={25} width={25} />
          </RoundedIcon>
        }
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onAction={workgroupDelete}
        loading={isDeleting}
      />
      <EditWorkgroupNameModal
        isOpen={updateNameModalOpen}
        onClose={handleEditOnClose}
        defaultValue={currentWorkgroup?.name || ""}
        onSubmit={(workgroupName: string) => {
          changeWorkgroupName(workgroupName);
        }}
        loading={apiLoading}
      />
    </>
  );
});

export default WorkgroupList;

const modalTextStyle = style({
  color: colours.primaryText,
});

const ellipsis = style({
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  verticalAlign: "middle",
  whiteSpace: "nowrap",
});