import { Content, Horizontal, Vertical } from "gls/lib";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { CreateWorkgroup } from "stores/config/WorkgroupsStore";
import { classes, style } from "typestyle";
import WorkgroupList from "./components/WorkgroupList";
import { useSystemAdminPermissionStore, useWorkgroupsStore } from "contexts/RootStore.context";
import { ComplexList } from "components/inputs/ComplexList/ComplexList";
import { PropsSchema } from "components/Renderer/Renderer";
import Button from "components/Button/Button";
import { colours } from "styling/colours";
import { NumberOfItems } from "components/NumberOfItems";
import ScrollingContainer from "components/ScrollContainer/ScrollingContainer";
import HeaderDefault from "components/Header/HeaderDefault";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import FormHeader from "views/AssetManagementPage/components/FormHeader";
import WidthNotSupportedPage from "views/WidthNotSupportedPage/WidthNotSupportedPage";
import useDevice from "hooks/useDevice";
import { formStyle } from "styling/config.style";

const schema: PropsSchema[] = [
  {
    id: "workgroupName",
    type: "Input",
    props: {
      label: "Workgroup Name:",
      type: "text",
      ariaLabel: "Workgroup Name",
      required: true,
      maxLength: 30,
    },
  },
  {
    id: "defaultRoles",
    type: "CheckBox",
    props: {
      containerClassName: style({ paddingTop: "42px" }),
      verticalAlign: "top",
      label: "Add default roles to workgroup",
      ariaLabel: "Add default roles to eorkgroup",
    },
  },
];

const Workgroups = observer(() => {
  const { canManageGroupsAndRoles } = useSystemAdminPermissionStore();
  const { isMobileDevice } = useDevice();

  const {
    createWorkgroupsForm,
    isValidForm,
    errors,
    reset,
    loadWorkgroups,
    workgroups,
    createWorkgroups,
    apiLoading
  } = useWorkgroupsStore();

  const createHandler = () => {
    createWorkgroups().then(() => {
      alertSuccessMessage(`Workgroup(s) have been created`)
    })
      .catch((error: ErrorResponseDto) => {
        alertErrorMessage(error.errorMsg)
      })
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const abortController = new AbortController();
    loadWorkgroups()
      .finally(() => {
        if (abortController.signal.aborted) {
          reset();
        }
      });

    return () => {
      abortController.abort();
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return (
    <>
      <ScrollingContainer>
        <HeaderDefault />
        {canManageGroupsAndRoles && (
          <>
            <FormHeader text="CREATE WORKGROUP" bold />
            <Vertical className={classes(formStyle)} padding={30}>
              <ComplexList
                editable
                minItems={1}
                maxItems={10}
                value={createWorkgroupsForm.$.createWorkgroups.value}
                errors={errors}
                addLabel="Add Workgroup Item"
                itemPropsSchema={schema}
                onChange={(newWorkgroups, newErrors) => {
                  createWorkgroupsForm.$.createWorkgroups.onChange(
                    newWorkgroups as CreateWorkgroup[]
                  );
                }}
                ariaLabel="WorkgroupItems"
                deleteModalProps={{
                  title: "Delete",
                  subtitle:
                    "Are you sure you want to delete the workgroup item?",
                  itemName: "Workgroup item",
                }}
                setDeleteItemName={undefined}
              />
            </Vertical>
            <Content padding={[4, 0, 0, 0]} horizontalAlign="right">
              <Button
                id="create-workgroup-button"
                onClick={() => {
                  createWorkgroupsForm.validate();
                  if (!isValidForm)
                    return;
                  createHandler();
                }}
                disabled={!isValidForm}
                isLoading={apiLoading}
              >
                Create
              </Button>
            </Content>
            <Horizontal
              style={{
                backgroundColor: "#0f0",
                borderTop: `1px solid ${colours.tertiaryText}`,
                marginTop: 28,
                marginBottom: 28
              }}
            ></Horizontal>
          </>
        )}
        <Vertical spacing={0}>
          <FormHeader text="VIEW AND MANAGE WORKGROUPS" bold />
          <NumberOfItems
            text="Total Workgroups"
            totalNumberOfItems={workgroups.length}
          />
        </Vertical>
        <WorkgroupList />
      </ScrollingContainer>
    </>
  );
});

export default Workgroups;